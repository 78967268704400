import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
          translation: {
            Welcome: {
              head: "I'm Taha Bisgin.",
              tail: "That's all you need to know.",
            },
            Projects: {
              title: "Featured Projects:",
              todo: "Do your Tasks.",
              budget1: "Use your Budget",
              budget2: "carefully.",
              pwgen1: "Please use strong",
              pwgen2: "passwords.",
            },
            Footer: {
              copy: "©TBSVSN, all rights reserved.",
            }
          },
        },
        de: {
          translation: {
            Welcome: {
              head: "Ich bin Taha Bisgin.",
              tail: "Mehr musst du nicht wissen.",
            },
            Projects: {
              title: "Einige Projekte:",
              todo: "Erledige Aufgaben.",
              budget1: "Nutze dein Budget",
              budget2: "vorsichtig.",
              pwgen1: "Verwende bitte sichere",
              pwgen2: "Passwörter.",
            },
            Footer: {
              copy: "©TBSVSN, alle rechte vorbehalten.",
            }
          },
        },
        tr: {
          translation: {
            Welcome: {
              head: "Ben Taha Bisgin.",
              tail: "Bilmeniz gereken tek şey bu.",
            },
            Projects: {
              title: "Bazı Projeler:",
              todo: "Hedeflerini not al.",
              budget1: "Bütçeni dikkatli",
              budget2: "kullan.",
              pwgen1: "Lütfen güçlü şifreler",
              pwgen2: "kullan.",
            },
            Footer: {
              copy: "©TBSVSN, Tüm hakları saklıdır.",
            }
          },
        },
        fr: {
          translation: {
            Welcome: {
              head: "Je suis Taha Bisgin.",
              tail: "C'est tout ce qu'il faut savoir.",
            },
            Projects: {
              title: "Quelques projets:",
              todo: "Accomplis tes tâches.",
              budget1: "Utilise ton budget avec",
              budget2: "prudence.",
              pwgen1: "Utilise des mots de",
              pwgen2: "passe sûrs.",
            },
            Footer: {
              copy: "©TBSVSN, Tous droits réservés.",
            }
          },
        },
      },
  });

export default i18n;