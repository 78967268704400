import React, { useEffect, useState } from 'react';
import './App.css';
import sun from './sun.svg';
import useLocalStorage from 'use-local-storage';
import { useTranslation } from 'react-i18next';

//Fonts
import './fonts/Thabit.ttf';
import './fonts/Thabit-Bold.ttf';
import './fonts/Inter.ttf';
import './fonts/Inter-Light.ttf';
import './fonts/Inter-Medium.ttf';
import './fonts/Inter-Bold.ttf';
import './fonts/Inter-SemiBold.ttf';
import './fonts/Inter-Black.ttf';

function App() {
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);

  useEffect(() => {
    // Update data-theme attribute on body based on isDark state
    document.body.setAttribute('data-theme', isDark ? 'dark' : 'light');
    // Trigger custom event to notify about theme change
    document.body.dispatchEvent(new Event('themeChange'));
  }, [isDark]);

  const { t } = useTranslation();

  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatNumber = (number) => {
    return number < 10 ? '0' + number : number;
  };

  const formattedDate = `${dateTime.getDate()} ${dateTime.getMonth() + 1} ${dateTime.getFullYear() % 100}`;
  const formattedTime = `${formatNumber(dateTime.getHours())}:${formatNumber(dateTime.getMinutes())}:${formatNumber(dateTime.getSeconds())}`;

  return (
    <body  data-theme={isDark ? "dark" : "light"} >
    <div className="App">
      <h4>{formattedDate} - {formattedTime}</h4>
      <header className="App-header">
      <h1><a href='/'>T²⁴</a></h1>
      
      <img
          src={sun}
          width={30}
          height={30}
          className='toggleTheme'
          alt="Toggle theme"     
          onClick={() => setIsDark(!isDark)}     
        />

      </header>
      <main className='App-main'>
        <div style={{marginBottom:'4.24%'}}>
          <h2>
          {t('Welcome.head')} <br></br><br></br>
          {t('Welcome.tail')}
          </h2>
        </div>
        
        <div className='project-items'>
        <h2>{t('Projects.title')}</h2>
          <a href='https://play.google.com/store/apps/details?id=com.tbsoftware.simplytodo' target="_blank" rel="noreferrer">
            <div className='project-item'>
              <h3>SimplyTodo.</h3>
              <p>{t('Projects.todo')}</p>
            </div>
          </a>
          <a href='https://github.com/tahabisginsoftware/MyBudget' target="_blank" rel="noreferrer">
            <div className='project-item'>
              <h3>MyBudget.</h3>
              <p>
              {t('Projects.budget1')}
              <br></br><br></br>
              {t('Projects.budget2')}
              </p>
            </div>
          </a>
          <a href='https://github.com/tahabisginsoftware/pwgen' target="_blank" rel="noreferrer">
            <div className='project-item'>
              <h3>pwgen.</h3>
              <p style={{marginRight:-24}}>  
              {t('Projects.pwgen1')}
              <br></br><br></br>
              {t('Projects.pwgen2')}
              </p>
            </div>
          </a>
        </div>
      </main>
    </div>

    <footer className='App-footer' data-theme={isDark ? "dark" : "light"}>
    <div className='footer-socials'>
    <a href='https://www.instagram.com/tbcoding' target="_blank" rel="noreferrer">Instagram</a>
    <a href='https://github.com/tahabisginsoftware' target="_blank" rel="noreferrer">Github</a>
    <a href='https://www.linkedin.com/in/tahabisgin/' target="_blank" rel="noreferrer">LinkedIn</a>
    </div>
    <h2>{t('Footer.copy')}</h2>
    </footer>
    </body>
  );
}

export default App;
